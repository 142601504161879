var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Application Forms")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Forms","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(
          _vm.currentUserRole.name == 'VH Superadmin' ||
          _vm.currentUserRole.name == 'Manager'
        )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","light":"","to":{ name: _vm.$route.name + '-archived' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}],null,false,3165519344)},[_c('span',[_vm._v("View Archived Applications")])])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"mt-4 mb-5",attrs:{"headers":_vm.tableHeaders,"items":_vm.forms,"no-data-text":"No forms found"},scopedSlots:_vm._u([{key:"item.submitted_date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.submitted_date))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mr-2",attrs:{"bottom":"","x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-easylets-applicationforms-individual',
                params: { formId: item.application_id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteForm.dialog),callback:function ($$v) {_vm.$set(_vm.deleteForm, "dialog", $$v)},expression:"deleteForm.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Form")]),_c('v-card-text',[_vm._v("Are you sure you wish to archive form?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteForm.loading},on:{"click":_vm.saveDelete}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }