<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Application Forms</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Forms"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col
          cols="auto"
          v-if="
            currentUserRole.name == 'VH Superadmin' ||
            currentUserRole.name == 'Manager'
          "
        >
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Applications</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="forms"
        class="mt-4 mb-5"
        no-data-text="No forms found"
      >
        <template v-slot:item.submitted_date="{ item }">
          {{ formatDate(item.submitted_date) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            bottom
            x-small
            depressed
            class="mr-2"
            color="red lighten-4 red--text"
            @click="openDelete(item)"
          >
            <v-icon x-small>mdi-archive</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-easylets-applicationforms-individual',
                  params: { formId: item.application_id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Form</v-card-title>
        <v-card-text>Are you sure you wish to archive form?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Application Forms",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Property", value: "full_adress" },
        { text: "Group", value: "group.lead.full_name" },
        { text: "Name", value: "customer.full_name" },
        { text: "Status", value: "form_status" },
        { text: "Date Submitted", value: "submitted_date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
    };
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    forms() {
      let forms = this.$store.getters["easylets/applicationformsStore/all"];

      if (this.searchTerm !== "") {
        forms = forms.filter((c) => {
          const propertyName = c.property
            ? c.property.full_address.toLowerCase()
            : c.address_text.toLowerCase();
          const lead =
            c.group !== null ? c.group.lead.full_name.toLowerCase() : "";
          const customer = c.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            propertyName.includes(searchTerm) ||
            customer.includes(searchTerm) ||
            lead.includes(searchTerm)
          );
        });
      }

      forms.map((item) => {
        item.full_adress = item.property_id
          ? item.property.full_address
          : item.address_text;
      });

      return forms;
    },
  },

  methods: {
    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>
